// @ts-check
import React, {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {loadInfoAction} from "../../../pages/day_view/day_view_actions";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../../redux/app_selectors";
import logger from "../../../utils/logger_pino";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {loadRoomsAction} from "../../rooms/rooms_actions";

const DataFetcher = () => {
    const dispatch = useDispatch();
    const organizationId = useSelector(selectCurrentOrganizationId);
    const {infoLayer: infoParams} = useSelector(selectFeSettings);
    const {now, format} = useContext(DateContext);
    const email = useSelector(selectCurrentUserEmail);

    useEffect(() => {
        if (organizationId) {
            dispatch(loadRoomsAction(organizationId));
            if (infoParams.info1 && infoParams.info2 && infoParams.info3) {
                dispatch(loadInfoAction({organizationId, date: format(now(), DATE_FORMATS.SYSTEM_DATE), infoParams}));
            } else {
                logger.warn("infoParams are not set in settings", {
                    organizationId,
                    email,
                    infoParams
                });
            }
        }
    }, [organizationId, infoParams]);

    return <div></div>;
};

export default DataFetcher;
